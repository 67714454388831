import { DIGITAL_BADGES_OG_IMAGE, PORTAL_ORIGIN, OG_IMAGE_SERVICE_URL } from '../config';
import { Lang } from '../localization';
import { EventTab } from '../type';

type LocalizationForUrl = { slug: string | null; lang: string };

type ContentForUrl = {
    slug: string;
    localizations?: LocalizationForUrl[];
};

const getContentLangUrlParam = (content: ContentForUrl) => {
    const lang: string = content.localizations?.find((l) => l.lang !== Lang.EN && l.slug === content.slug)?.lang || '';
    return lang ? `/${lang.toLowerCase()}` : lang;
};

export function getLanguagePrefix(lang?: string | null) {
    return lang && lang !== Lang.EN ? `/${lang.toLowerCase()}` : '';
}

export function getLocalizationUrl(l: LocalizationForUrl) {
    return `${l.slug}${getLanguagePrefix(l.lang)}`;
}

export function getFullContentLocalizationUrl(l: LocalizationForUrl) {
    return `${PORTAL_ORIGIN}/contents/${getLocalizationUrl(l)}`;
}

export function getContentUrl(content: ContentForUrl) {
    return `/contents/${content.slug}${getContentLangUrlParam(content)}`;
}

export function getFullContentUrl(content: ContentForUrl) {
    return `${PORTAL_ORIGIN}${getContentUrl(content)}`;
}

export function getFullTagUrl(tag: { slug: string }): string {
    return `${PORTAL_ORIGIN}/tags/${tag.slug}`;
}

export function getFullPersonUrl(user: { nickname?: string | null; id: number }) {
    return `${PORTAL_ORIGIN}/person/${user.nickname ?? user.id}`;
}

export function getContentOgImageUrl(content: { slug: string }): string {
    return `${OG_IMAGE_SERVICE_URL}/${content.slug}`;
}

export function getEventPageUrl(event: { slug: string | null; id: number }) {
    return `${PORTAL_ORIGIN}/events/${event.slug ?? event.id}`;
}
export function getEventPageTabUrlParam(eventSlug: string, tab: string | null, lang?: string) {
    if (tab === EventTab.WORKSHOPS) {
        return `/events/${eventSlug}${getLanguagePrefix(lang)}?filter=${EventTab.WORKSHOPS}`;
    }
    return `/events/${eventSlug}${tab ? `/${tab}` : ''}${getLanguagePrefix(lang)}`;
}

export function getBadgeEventUrl(eventSlug: string) {
    return `${PORTAL_ORIGIN}/badges/${eventSlug}`;
}

export function getBadgeUrl(user: { nickname?: string | null; id: number }, eventSlug: string) {
    return `${getBadgeEventUrl(eventSlug)}/${user.nickname ?? user.id}`;
}

export function getCertificateUrl(certificate: { id: number; user: { nickname: string | null; id: number } }) {
    const { user } = certificate;

    return `${PORTAL_ORIGIN}/person/${user.nickname ?? user.id}/certificates/${certificate.id}`;
}

export function getDefaultAvatarUrl() {
    return `${PORTAL_ORIGIN}/default-avatar.jpeg`;
}

export function getBadgeImageUrl(
    user: { nickname?: string | null; id: number; updatedAt: Date | string },
    targetUrl?: string,
    from?: string,
) {
    const updatedAtInMs = new Date(user.updatedAt!).getTime();

    return `${DIGITAL_BADGES_OG_IMAGE}/${user.nickname ?? user.id}.png?v5&date=${updatedAtInMs}${
        from ? `&appendParam=${encodeURIComponent(`from=${from}`)}` : ''
    }${targetUrl ? `&targetUrl=${encodeURIComponent(targetUrl)}` : ''}`;
}

export function getCertificateOgImageUrl(
    certificate: { id: number; updatedAt?: Date | string | null },
    user: { updatedAt?: Date | string | null },
) {
    let imageDate = 0;
    if (user.updatedAt) {
        imageDate += new Date(user.updatedAt).getTime();
    }
    if (certificate.updatedAt) {
        imageDate += new Date(certificate.updatedAt).getTime();
    }

    return `${DIGITAL_BADGES_OG_IMAGE}/certificate.png?certId=${certificate.id}${
        imageDate ? `&date=${imageDate}` : ''
    }`;
}

export function getConferenceTicketsUrl(brandDomain: string) {
    // brand domain: https://example.com or https://example.com/
    const urlObj = new URL(brandDomain);
    return urlObj.origin + '/?utm_source=portal&utm_medium=organic&utm_campaign=portal_visits#tickets';
}

export function getConferenceWebsiteUrl(brandDomain: string) {
    // brand domain: https://example.com or https://example.com/
    const urlObj = new URL(brandDomain);
    return urlObj.origin + '/?utm_source=portal&utm_medium=organic&utm_campaign=portal_visits';
}

const BASE_URL = {
    twitter: ['twitter.com', 'x.com'],
    github: ['github.com'],
    linkedin: ['linkedin.com/in'],
    instagram: ['instagram.com'],
    tiktok: ['tiktok.com'],
};
export function normalizeSocialUrl(url: string, type: 'twitter' | 'github' | 'linkedin' | 'instagram' | 'tiktok') {
    const base = BASE_URL[type];

    if (base.some((baseUrl) => url.includes(baseUrl))) {
        return url;
    } else {
        let handle = url;
        if (type === 'twitter') {
            handle = handle.replace('@', '');
        }

        return `https://${base[base.length - 1]}/${handle}`;
    }
}

export function sanitizeUrl(url: string | null) {
    if (!url || !/[a-z0-9]+\.[a-z0-9]+/i.test(url)) return null;

    return url.replace(/\s/g, '').toLowerCase();
}

export function getRawQueryParams(url: string): Record<string, string> {
    if (url.includes('?')) {
        const search = url.substring(url.indexOf('?') + 1);
        const params = search.split('&').reduce((map, paramStr) => {
            const [key, value] = paramStr.split('=');

            if (key && value) {
                map[key] = decodeURIComponent(value);
            }
            return map;
        }, {} as Record<string, string>);

        return params;
    }
    return {};
}

export function constructUrl(base: string, params?: { [key: string]: string | null | undefined }) {
    const searchParams =
        params &&
        Object.entries(params).reduce((searchParams: URLSearchParams, [key, value]) => {
            if (!searchParams) {
                searchParams = new URLSearchParams();
            }

            if (value) {
                searchParams.append(key, value);
            }

            return searchParams;
        }, null);

    return base + `${searchParams ? `?${searchParams.toString()}` : ''}`;
}

export const getLastIdFromURL = (url) => {
    return +url.substring(url.lastIndexOf('/') + 1, url.includes('?') ? url.lastIndexOf('?') : url.length);
};
