import fetch from 'node-fetch';
import { UPLOAD_PRESET } from '../../config';
import fs from 'fs';
import { CloudinarySignature, CloudinaryUploadParams } from './types';
import { cloudinaryConfig } from './config';
import { EXTENSION_TO_MIME } from './utils';

const CDN_CLOUDINARY = 'cdn.cloudinary.com';
const CDN_IMGIX = 'gitnation.imgix.net';

// TODO: to refactor
export async function upload(file: File, params?: { [key: string]: string | number }) {
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', UPLOAD_PRESET);

    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            data.set(key, String(value));
        });
    }

    const res = await fetch(`https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/image/upload`, {
        method: 'POST',
        // @ts-ignore
        body: data,
    });
    if (res.status === 200) {
        const response = await res.json();
        const secureUrl = response.secure_url;
        const cdnUrl = secureUrl.replace('res.cloudinary', 'cdn.cloudinary');
        return cdnUrl;
    } else {
        throw new Error('Error while uploading to cloudinary');
    }
}

export async function destroy(publicId: string, params?: { [key: string]: string | number }) {
    const data = new FormData();
    data.append('public_id', publicId);

    data.append('invalidate', 'true');

    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            data.append(key, String(value));
        });
    }

    const res = await fetch(`https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/image/destroy`, {
        method: 'POST',
        // @ts-ignore
        body: data,
    });

    if (res.ok) {
        const response = await res.json();
        console.log('Image deleted, response:', response);
        return response;
    } else {
        const errorText = await res.text();
        throw new Error(`Error while deleting image from Cloudinary: ${errorText}`);
    }
}

export async function createCloudinarySignature(
    apiUrl: string,
    signParams: CloudinaryUploadParams,
    antiCsrfToken: string,
): Promise<CloudinarySignature> {
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'anti-csrf': antiCsrfToken },
        body: JSON.stringify(signParams),
    });

    return response
        .text()
        .catch((error) => {
            console.error('Failed to convert response to text', response, signParams);
            throw error;
        })
        .then((text) => {
            try {
                if (text) {
                    return JSON.parse(text);
                }

                throw new Error('Empty signature object');
            } catch (error) {
                console.error('Failed to convert response to json', response, signParams, text);
                throw error;
            }
        });
}

/**
 * See public_id character limitations
 * @link https://cloudinary.com/documentation/image_upload_api_reference#upload_optional_parameters
 */
export function encodeCloudinaryPublicId(publicId: string): string {
    return encodeURIComponent(publicId).replace(/%/g, '@');
}

/**
 * Allow only few width values to reduce number of cloudinary transformations
 */
export function getResizedCloudinaryImage(
    url?: string,
    width: 60 | 300 | 600 = 300,
    useImgix = true,
): string | undefined {
    if (url && url.indexOf('cloudinary.com') > -1) {
        const [protocol, empty, _hostname, cloud, type, action, version, ...path] = url.split('/');
        const resizingAdditions = ['f_auto', 'c_scale', `w_${width}`];

        if (useImgix) {
            const imgixParams = toImgix(resizingAdditions);

            let fullUrl = [protocol, empty, CDN_IMGIX, cloud, type, action, version, ...path].join('/');
            if (imgixParams.length) {
                fullUrl += '?' + imgixParams.join('&');
            }

            return fullUrl;
        }

        const fullUrl = [
            protocol,
            empty,
            CDN_CLOUDINARY,
            cloud,
            type,
            action,
            resizingAdditions.join(','),
            version,
            ...path,
        ].join('/');
        return fullUrl.includes('?') ? fullUrl : fullUrl + '?auto=format';
    }
    return url;
}

type Extension = keyof typeof EXTENSION_TO_MIME;
type CloudinaryImageParams = {
    url?: string | null;
    width?: number;
    height?: number;
    transformations?: CloudinaryTransformation[] | string[];
    format?: Extension;
    useImgix?: boolean;
};
export const CloudinaryTransformation = {
    scale: 'c_scale',
    g_face: 'g_face',
    fill: 'c_fill',
    fit: 'c_fit',
} as const;
export type CloudinaryTransformation = typeof CloudinaryTransformation[keyof typeof CloudinaryTransformation];
/** New version of the method from above */
export function getCloudinaryImageUrl({
    url,
    width = 300,
    height,
    transformations = [CloudinaryTransformation.scale],
    format,
    useImgix = true,
}: CloudinaryImageParams) {
    if (url && url.indexOf('cloudinary.com') > -1) {
        const getWidth = () => {
            return `w_${width}`;
        };
        const getHeight = () => {
            return height ? `h_${height}` : null;
        };

        const [protocol, empty, _hostname, cloud, type, action, version, ...path] = url.split('/');
        const resizingAdditions = ['f_auto', ...transformations, getWidth(), getHeight()].filter(Boolean) as string[];
        let handle = [version, ...path].join('/');

        if (useImgix) {
            const imgixParams = toImgix(resizingAdditions);
            if (format) {
                imgixParams.push(`fm=${format.replace('.', '')}`);
            }

            let fullUrl = [protocol, empty, CDN_IMGIX, cloud, type, action, version, ...path].join('/');
            if (format) {
                fullUrl = replaceExtension(fullUrl, format);
            }
            if (imgixParams.length) {
                fullUrl += '?' + imgixParams.join('&');
            }

            return {
                url: fullUrl,
                handle,
            };
        }

        let fullUrl = [
            protocol,
            empty,
            CDN_CLOUDINARY,
            cloud,
            type,
            action,
            resizingAdditions.join(','),
            version,
            ...path,
        ].join('/');

        if (format) {
            fullUrl = replaceExtension(fullUrl, format);
            handle = replaceExtension(handle, format);
        }

        return {
            url: fullUrl.includes('?') ? fullUrl : fullUrl + '?auto=format',
            handle,
        };
    }

    return { url, handle: null };
}

const replaceExtension = (url: string, format: string) => {
    for (const extension of Object.keys(EXTENSION_TO_MIME)) {
        if (url.includes(extension)) {
            return url.replace(extension, format);
        }
    }
    return url;
};

const toImgix = (transformations: string[]) => {
    const params = new Map<string, string>();
    for (const transformation of transformations) {
        switch (transformation) {
            case 'f_auto':
                // return null;
                params.set('auto', 'format,compress');
                break;
            case CloudinaryTransformation.fill:
                params.set('fit', 'crop');
                break;
            case CloudinaryTransformation.fit:
                params.set('fit', 'clip');
                break;
            case CloudinaryTransformation.scale:
                params.set('fit', 'scale');
                break;
            case CloudinaryTransformation.g_face:
                // not an exact replacement, but close to ideal
                params.set('fit', 'facearea');
                params.set('facepad', '5');
                break;
            default:
                if (transformation.includes('=')) {
                    const [key, value] = transformation.split('=');
                    params.set(key, value);
                } else if (transformation.includes('_')) {
                    const [key, value] = transformation.split('_');
                    params.set(key, value);
                }
        }
    }
    return Array.from(params.entries()).map(([key, value]) => key + '=' + value);
};

export const download = async (url, path) => {
    const res = await fetch(url);
    const fileStream = fs.createWriteStream(path);
    await new Promise((resolve, reject) => {
        res.body.pipe(fileStream);
        res.body.on('error', reject);
        fileStream.on('finish', resolve);
    });
};
